import React from "react"
import { Col, Row } from "reactstrap"
import Asset from "../Asset"
import ReactHtmlParser from "react-html-parser"
import LinkItem from "../LinkItem"
import classNames from "classnames"

const ResizableAssetWithContent = ({ translatedSection, defaultSection }) => {
  const renderedElements = defaultSection.resizableAssetsWithContent.map(
    (resizableAsset, index) => {
      const translatedAsset =
        translatedSection.resizableAssetsWithContent[index]

      return (
        <Col
          xl={resizableAsset.desktopItemWidth}
          md={resizableAsset.tabletItemWidth}
          sm={resizableAsset.mobileItemWidth}
          className={classNames("ResizableAssetWithContent", {
            "ResizableAssetWithContent--highlighted":
              resizableAsset.highlighted,
          })}
        >
          <Asset asset={resizableAsset.assetWithContent.asset} />
          <div className="ResizableAssetWithContent__text-content">
            <h3 className="ResizableAssetWithContent__title">
              {translatedAsset.assetWithContent.title}
            </h3>
            <p className="ResizableAssetWithContent__description">
              {ReactHtmlParser(
                translatedAsset.assetWithContent.description.html
              )}
            </p>
          </div>
          {resizableAsset.assetWithContent.linkItem && (
            <LinkItem
              linkItemData={translatedAsset.assetWithContent.linkItem}
              defaultLinkItemData={resizableAsset.assetWithContent.linkItem}
            />
          )}
        </Col>
      )
    }
  )
  return (
    <Row>
      <h3 className="section-header">{translatedSection.title}</h3>
      {renderedElements}
    </Row>
  )
}

export default ResizableAssetWithContent
