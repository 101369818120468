import React from "react"
import ReactHtmlParser from "react-html-parser"
import { Row } from "reactstrap"
import FaqList from "../FaqList"
import LinkWithArrow from "../LinkWithArrow"
import SectionHeader from "../SectionHeader"

const FAQSection = ({ translatedSection }) => {
//  const formattedFaqItems = translatedSection.faqs.map(
//    ({ title, answer, id }) => {
//      return {
//        title,
//        answer: ReactHtmlParser(answer.html),
//        id,
//      }
//    }
//  )

//  let link: JSX.Element | null = null

//  if (translatedSection.linkItem) {
//    link = (
//      <div className="Manual__media-wrapper__description__link">
//        <LinkWithArrow
//          center
//          blue
//          url={translatedSection.linkItem.link}
//          text={translatedSection.linkItem.text}
//        />
//      </div>
//    )
 // }

return(null)

//  return (
//    <Row>
//      {translatedSection.title && (
//        <SectionHeader title={translatedSection.title} center />
//      )}
//      <FaqList items={formattedFaqItems} />
//      {link}
//    </Row>
//  )
}

export default FAQSection
