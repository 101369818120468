import React from "react"
import InformationCard from "../press/InformationCard"
import SectionHeader from "../SectionHeader"

const PressSection = ({ translatedSection }) => {
//  const renderedPress = translatedSection.pressItems.map(pressEntry => {
//    return (
//      <InformationCard
//        date={pressEntry.date}
//        place={pressEntry.place}
//        title={pressEntry.title}
//      />
//    )
//  })

return(null)

//  return (
//    <>
//      <SectionHeader
//        title={translatedSection.title}
//        description={translatedSection.subTitle}
//        center
//      />
//      <div className="w-100 d-flex flex-wrap Press__container">
//        {renderedPress}
//      </div>
//    </>
//  )
}

export default PressSection
