import React, { useState } from "react"
import Lightbox from "react-image-lightbox"
import Image from "../Image"
import "react-image-lightbox/style.css"

const GallerySection = ({ translatedSection, defaultSection }) => {
  const [imageToOpen, setImageToOpen] = useState<null | string>(null)

  const renderedImages = defaultSection.galleryAssets.map(image => (
    <Image
      onClick={() => setImageToOpen(image.localFile.publicURL)}
      divWrapperClass="GallerySection__image-container"
      key={image.id}
      src={image.localFile.publicURL}
    />
  ))

  const isOpen = !!imageToOpen

  return (
    <div tabIndex={1}>
      <div className="GallerySection">
        <h2 className="GallerySection__title">{translatedSection.title}</h2>
        <div className="GallerySection__image-wrapper">{renderedImages}</div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={imageToOpen}
          onCloseRequest={() => setImageToOpen(null)}
        />
      )}
    </div>
  )
}

export default GallerySection
