import classNames from "classnames"
import React from "react"
import ReactHtmlParser from "react-html-parser"

const SectionTitle = ({
  title,
  textAlign = "center",
  backgroundEffect = true,
  description = null,
}) => {
  return (
    <div className={`text-${textAlign} w-100`}>
      <h2
        className={classNames({
          "title-background-effect": backgroundEffect,
        })}
      >
        {title}
      </h2>
      {description && (
        <p className="titleSection__description">
          {ReactHtmlParser(description.html)}
        </p>
      )}
    </div>
  )
}

export default SectionTitle
