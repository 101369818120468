import React from "react"

type SectionHeaderProps = {
  title: string
  description?: string
  center?: boolean
}

const SectionHeader = ({ title, description, center }: SectionHeaderProps) => {
  return (
    <div className="SectionHeader">
      {description && (
        <div
          className={`SectionHeader__description ${
            center ? "text-center" : ""
          }`}
        >
          {description}
        </div>
      )}
      <h3 className={`SectionHeader__title ${center ? "text-center" : ""}`}>
        {title}
      </h3>
    </div>
  )
}

export default SectionHeader
