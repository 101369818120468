import React from "react"
import { Col, Row } from "reactstrap"
import Image from "../Image"
import ReactHtmlParser from "react-html-parser"
import LinkItem from "../LinkItem"

const InstructionSection = ({ translatedSection, defaultSection }) => {
  const renderedLinkItems = translatedSection.linkItems.map(
    (translatedLink, index) => {
      return (
        <LinkItem
          btnClassName="InstructionSection__link"
          linkItemData={translatedLink}
          defaultLinkItemData={defaultSection.linkItems[index]}
        />
      )
    }
  )

  const instructionsRendered = defaultSection.resizableAssetsWithContent.map(
    (instructionDef, index) => {
      return (
        <Col
          className="InstructionSection__column"
          xl={instructionDef.desktopItemWidth}
          md={instructionDef.tabletItemWidth}
          sm={instructionDef.mobileItemWidth}
        >
          <div className="position-relative d-flex justify-content-center ">
            <Image
              style={{ height: "200px", objectFit: "contain", width: "100%" }}
              imgStyle={{ objectFit: "contain" }}
              src={instructionDef.assetWithContent.asset.localFile.publicURL}
            />
            <div className="InstructionSection__column__order">{index + 1}</div>
          </div>
          <h2 className="InstructionSection__column__title">
            {
              translatedSection.resizableAssetsWithContent[index]
                .assetWithContent.title
            }
          </h2>
          <p className="InstructionSection__column__description">
            {ReactHtmlParser(
              translatedSection.resizableAssetsWithContent[index]
                .assetWithContent.description.html
            )}
          </p>
        </Col>
      )
    }
  )

  return (
    <div>
      <h2 className="InstructionSection__title">{translatedSection.title}</h2>
      <Row className="mt-4">{instructionsRendered}</Row>
      <div className="text-center">{renderedLinkItems}</div>
    </div>
  )
}

export default InstructionSection
