import React, { useContext } from "react"
import { Col, Row } from "reactstrap"
import Heart from "../../icons/heart.svg"
import { graphql, Link, useStaticQuery } from "gatsby"
import ReactHtmlParser from "react-html-parser"
import { LanguageContext } from "../../context/LanguageContext"
import Image from "../Image"

const Footer = () => {
  const queryResult = useStaticQuery(graphql`
    query FooterQuery {
      allGraphCmsFooter(filter: { title: { eq: "Main" } }) {
        nodes {
          locale
          id
          title
          logo {
            localFile {
              publicURL
            }
          }
          footerGroups {
            title
            id
            pages {
              slug
              title
              id
            }
          }
          address
          organization
          companyAddress {
            html
          }
          companyName
          contact {
            email
            phone
            linkedinLink
            linkedinLogo {
              localFile {
                publicURL
              }
            }
            facebookLink
            facebookLogo {
              localFile {
                publicURL
              }
            }
            title
            contactData {
              html
            }
          }
          additionalGroup {
            title
            id
            pages {
              id
              title
              slug
            }
          }
          copyrightText
          additionalText
          subFooterBackground {
            localFile {
              publicURL
            }
          }
          subFooterBackgroundMobile {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  const { language } = useContext(LanguageContext)

  const translationObject = queryResult.allGraphCmsFooter.nodes.find(
    translationObject => {
      return translationObject.locale === language
    }
  )

  const defaultGraphData = queryResult.allGraphCmsFooter.nodes[0]

  const columns: JSX.Element[] = []
  if (translationObject?.footerGroups?.length > 0) {
    translationObject.footerGroups.forEach(element => {
      const listItems = element.pages.map(row => (
        <li className="Footer__group__links__item" key={row.id}>
          <Link to={`/${row.slug}`}>{row.title}</Link>
        </li>
      ))

      columns.push(
        <Col md={2} key={element.id} className="Footer__group">
          <h3 className="Footer__group__title">{element.title}</h3>
          <ul className="Footer__group__links">{listItems}</ul>
        </Col>
      )
    })
  }

  const subColumns: JSX.Element[] = []
  if (translationObject?.additionalGroup) {
    translationObject.additionalGroup.pages.forEach(row => {
      subColumns.push(
        <li className="Footer__content__footer-row" key={row.id}>
          <Link to={`/${row.slug}`}>{row.title}</Link>
        </li>
      )
    })
  }

  return (
    <footer className="Footer" id="contact">
      <div className="Footer__sub-footer">
        {defaultGraphData.subFooterBackground.localFile.publicURL && (
          <div className="Footer__sub-footer__desktop-image">
            <Image
              src={defaultGraphData.subFooterBackground.localFile.publicURL}
            />
          </div>
        )}
        {defaultGraphData.subFooterBackgroundMobile.localFile.publicURL && (
          <div className="Footer__sub-footer__mobile-image">
            <Image
              src={
                defaultGraphData.subFooterBackgroundMobile.localFile.publicURL
              }
            />
          </div>
        )}
        <div className="Footer__sub-footer__text">
          {translationObject.additionalText}
        </div>
      </div>
      <div className="Footer__wrapper">
        <div className="Footer__header">
          <div className="Footer__logo">
            <img src={defaultGraphData.logo.localFile?.publicURL} />
          </div>
          <div className="Footer__social-links-wrapper">
            <div className="Footer__social-links">
              <a href={translationObject.contact.facebookLink}>
                <img
                  src={
                    defaultGraphData.contact.facebookLogo.localFile?.publicURL
                  }
                />
              </a>
              <a href={translationObject.contact.linkedinLink}>
                <img
                  src={
                    defaultGraphData.contact.linkedinLogo.localFile?.publicURL
                  }
                />
              </a>
            </div>
          </div>
        </div>

        <div className="Footer__info-wrapper">
          <div className="Footer__info">{translationObject.address}</div>
          <div className="Footer__info">{translationObject.organization}</div>
          <div className="Footer__info">{translationObject.contact.email}</div>
          <div className="Footer__info">{translationObject.contact.phone}</div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
