import React from "react"
import ImageWithBottomDescription from "../ImageWithBottomDescription"
import ReactHtmlParser from "react-html-parser"

const StepsSection = ({ defaultSection, translatedSection }) => {
  const steps =
    translatedSection.stepItems?.map((step, stepIndex) => {
      return (
        <ImageWithBottomDescription
          key={`step--${stepIndex}`}
          title={step.title}
          imageSrc={
            defaultSection.stepItems[stepIndex].image.localFile.publicURL
          }
          description={ReactHtmlParser(step.description.html)}
        />
      )
    }) ?? []

  return <div className="d-flex flex-wrap Manual__steps">{steps}</div>
}

export default StepsSection
