import React, { useEffect, useState } from "react"
import Image from "../Image"
import PersonCard from "../PersonCard"
import ReactHtmlParser from "react-html-parser"
import SectionHeader from "../SectionHeader"
import { Carousel } from "react-responsive-carousel"
import PersonCardModal from "../PersonCardModal"

type ClickedPersonInfo = {
  img
  description
  title
  role
}

const TeamSection = ({ defaultSection, translatedSection }) => {
  const [clickedPerson, setClickedPerson] = useState<any | null>(null)
  const closeModal = () => setClickedPerson(null)

  const renderedGroups = translatedSection.graspTeamMemberGroups.map(
    (group, groupIndex) => {
      const persons = group.graspTeamMembers.map((member, memberIndex) => {
        const imageSrc =
          defaultSection.graspTeamMemberGroups[groupIndex].graspTeamMembers[
            memberIndex
          ].photo.localFile.publicURL

        const onMemberClick = () => {
          const info: ClickedPersonInfo = {
            img: imageSrc,
            title: member.name,
            role: member.role,
            description: member.description.html,
          }

          setClickedPerson(info)
        }

        return (
          <PersonCard
            img={<Image src={imageSrc} />}
            key={member.id}
            name={member.name}
            description={
              member.description
                ? ReactHtmlParser(member.description.html)
                : null
            }
            email={member.email}
            role={member.role}
            onClick={onMemberClick}
          />
        )
      })

      return (
        <div key={`group-${groupIndex}`}>
          <Carousel
            autoPlay
            centerMode
            showArrows
            showIndicators
            showStatus={false}
            showThumbs={false}
            infiniteLoop
            className="TeamSection__carousel"
          >
            {persons}
          </Carousel>
          {group.displayTitle && (
            <SectionHeader
              title={group.title}
              description={group.subTitle}
              center
            />
          )}
          <div className="TeamSection__person-container">{persons}</div>
        </div>
      )
    }
  )

  const onClose = ev => {
    ev.preventDefault()
    ev.stopPropagation()
    closeModal()
  }

  const isClicked = !!clickedPerson

  return (
    <div>
      <h3 className="section-header">{translatedSection.title}</h3>
      {renderedGroups}
      {isClicked && <PersonCardModal onClose={onClose} {...clickedPerson} />}
    </div>
  )
}

export default TeamSection
