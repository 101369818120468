import React from "react"
import { Col } from "reactstrap"

export type CardProps = {
  title: string
  description: string
  border?: boolean
}

const Card: React.FC<CardProps> = ({
  description,
  title,
  children,
  border = false,
}) => {
  return (
    <Col
      md={12}
      lg={6}
      xl={3}
      className={`Card ${border ? "Card--border" : ""}`}
    >
      <div className="Card__image_container">{children}</div>
      <h3 className="Card__title">{title}</h3>
      <div className="Card__description">{description}</div>
    </Col>
  )
}

export default Card
