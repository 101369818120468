import React from "react"
import { Col, Container, Row } from "reactstrap"
import { Link } from "gatsby"

//types
import { SectionTypes } from "./constants"

//components
import Image from "../components/Image"
import OrderGraspSection from "../components/sections/OrderGraspSection"
import ImageWithTwoDescriptions from "../components/sections/ImageWithTwoDescriptionsSection"
import PartnersSection from "../components/sections/PartnersSection"
import TeamSection from "../components/sections/TeamSection"
import PilotSection from "../components/sections/PilotSection"
import MediaItemSection from "../components/sections/MediaItemSection"
import PressSection from "../components/sections/PressSection"
import SpecialistSection from "../components/sections/SpecialistSection"
import TextSection from "../components/sections/TextSection"
import FAQSection from "../components/sections/FaqSection"
import StepsSection from "../components/sections/StepsSection"
import CardSection from "../components/sections/CardSection"
import VideoSection from "../components/sections/VideoSection"
import NumberSection from "../components/sections/NumbersSection"
import ImageWithLinkSection from "../components/sections/ImageWithLinkSection"
import DownloadSection from "../components/sections/DownloadSection"
import ComingSoonSection from "../components/sections/ComingSoonSection"
import SectionTitle from "../components/sections/SectionTitle"
import ResizableAssetWithContent from "../components/sections/ResizableAssetWithContent"
import ArticleSwitch from "../components/ArticleSwitch"
import InstructionSection from "../components/sections/InstructionSection"
import GallerySection from "../components/sections/GallerySection"

export const sectionRender = (translationObject, defaultObject) => {
  const { isEvenColored = false } = translationObject

  const filteredPageSections = translationObject.pageSections.filter(
    el => el.isHidden != true
  )

  const filteredDefaultObject = {
    ...defaultObject,
    pageSections: defaultObject.pageSections.filter(el => el.isHidden != true),
  }

  return filteredPageSections.map((pageSection, sectionIndex) => {
    const isEven = (sectionIndex + 1) % 2 == 0

    const defaultPageSection = filteredDefaultObject.pageSections[sectionIndex]

    const sectionData = {
      defaultSection: defaultPageSection,
      translatedSection: pageSection,
    }

    let jsxElement: JSX.Element | null = null

    switch (pageSection.type) {
      case SectionTypes.MediaItem:
        jsxElement = <MediaItemSection {...sectionData} />
        break
      case SectionTypes.SpecialistSection:
        jsxElement = <SpecialistSection {...sectionData} />
        break
      case SectionTypes.PressSection:
        jsxElement = <PressSection {...sectionData} />
        break
      case SectionTypes.TextSection:
        jsxElement = <TextSection {...sectionData} />
        break
      case SectionTypes.ImageWithTwoDescriptions:
        jsxElement = <ImageWithTwoDescriptions {...sectionData} />
        break
      case SectionTypes.PartnersSection:
        jsxElement = <PartnersSection {...sectionData} />
        break
      case SectionTypes.TeamSection:
        jsxElement = <TeamSection {...sectionData} />
        break
      case SectionTypes.PilotSection:
        jsxElement = <PilotSection {...sectionData} />
        break
      case SectionTypes.FAQSection:
        jsxElement = <FAQSection {...sectionData} />
        break
      case SectionTypes.OrderSection:
        jsxElement = <OrderGraspSection {...sectionData} />
        break
      case SectionTypes.StepsSection:
        jsxElement = <StepsSection {...sectionData} />
        break
      case SectionTypes.CardSection:
        jsxElement = <CardSection {...sectionData} />
        break
      case SectionTypes.VideoSection:
        jsxElement = <VideoSection {...sectionData} />
        break
      case SectionTypes.ResizableAssetWithContent:
        jsxElement = <ResizableAssetWithContent {...sectionData} />
        break
      case SectionTypes.SectionTitle:
        jsxElement = (
          <SectionTitle
            title={pageSection.title}
            backgroundEffect={pageSection.titleBackgroundEffect}
            textAlign={pageSection.textPosition}
            description={pageSection.description}
          />
        )
        break
      case SectionTypes.ImageSection:
        jsxElement = jsxElement = (
          <Row>
            <Col>
              <Image
                src={
                  defaultObject.pageSections[sectionIndex].asset.localFile
                    .publicURL
                }
              />
            </Col>
          </Row>
        )
        break
      case SectionTypes.ButtonSection:
        jsxElement = (
          <div className="container d-flex justify-content-center">
            <Link to={defaultObject.link} className="CustomButton ">
              {translationObject.buttonText}
            </Link>
          </div>
        )
        break
      case SectionTypes.NumbersSection:
        jsxElement = <NumberSection {...sectionData} />
        break
      case SectionTypes.ImagesWithLinkSection:
        jsxElement = <ImageWithLinkSection {...sectionData} />
        break
      case SectionTypes.DownloadSection:
        jsxElement = <DownloadSection {...sectionData} />
        break
      case SectionTypes.WorkInProgress:
        jsxElement = <ComingSoonSection {...sectionData} />
        break
      //case SectionTypes.ArticleSection:
      //  const articleType = pageSection.articleGroup.articleType

      //  const componentProps = {
      //    defaultTranslation: filteredDefaultObject.pageSections[sectionIndex],
      //    translation: pageSection,
      //  }

      //  const articleData = {
      //    translation: pageSection.articleGroup,
      //    defaultTranslation:
      //      filteredDefaultObject.pageSections[sectionIndex].articleGroup,
      //  }

      //  jsxElement = (
      //    <ArticleSwitch
      //      articleType={articleType}
      //      {...articleData}
      //      isTotalHidden={false}
      //    />
      //  )
      //  break
      case SectionTypes.InstructionSection:
        jsxElement = <InstructionSection {...sectionData} />
        break
      case SectionTypes.GallerySection:
        jsxElement = <GallerySection {...sectionData} />
        break
    }

    if (isEven && isEvenColored)
      return (
        <div
          className="section-wrapper"
          key={`section-${sectionIndex}`}
          id={pageSection?.sectionId}
        >
          <div className="Manual__media-wrapper Manual__media-wrapper--with-background ">
            <Container>{jsxElement}</Container>
          </div>
        </div>
      )
    else
      return (
        <div
          key={`section-${sectionIndex}`}
          className="section-wrapper"
          id={pageSection?.sectionId}
        >
          <Container>{jsxElement}</Container>
        </div>
      )
  })
}
