import * as React from "react"
import Footer from "./Footer"
import Header from "./Header"

const Layout = ({ children }) => {
  const params =
    typeof window !== "undefined"
      ? new URLSearchParams(window.location.search)
      : null

  const embedded = !!params ? params.get("embedded") : null
  const isEmbedded = embedded === "true"

  return (
    <>
      {!isEmbedded && <Header />}
      <main>{children}</main>
      {!isEmbedded && <Footer />}
    </>
  )
}

export default Layout
