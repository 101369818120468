import React from "react"
import { Table } from "reactstrap"
import LinkWithArrow from "../LinkWithArrow"

const PilotSection = ({ defaultSection, translatedSection }) => {
  const pilots = translatedSection.pilotEntries.map((pilot, pilotIndex) => {
    const imageSrc =
      defaultSection.pilotEntries[pilotIndex].logo.localFile.publicURL
    return (
      <tr key={`program--${pilotIndex}`}>
        <td>
          <img src={imageSrc} />
        </td>
        <td>
          <p className="pilot-header">{pilot.title}</p>
          {pilot.description}
        </td>
        <td>{pilot.place}</td>
        <td>
          <div>
            <span
              className={`Programs__status${
                pilot.programStatus ? " active" : ""
              }`}
            />
            <span>{pilot.programStatus ? "Open" : "Closed"}</span>
          </div>
        </td>
        <td>
          <LinkWithArrow blue text="" url={`/program_details?id=${pilot.id}`} />
        </td>
      </tr>
    )
  })

  return (
    <>
      <div>
        <div className="header">
          <h3>Available pilots list:</h3>
          <div className="number-container">
            <span>{translatedSection.pilotEntries.length}</span>
          </div>
        </div>
        <div>
          You can register by clicking “Register” Button under every pilot
        </div>
      </div>
      <Table striped className="Programs">
        <thead>
          <tr>
            <th>Program Name</th>
            <th></th>
            <th>Place</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{pilots}</tbody>
      </Table>
    </>
  )
}

export default PilotSection
