import React from "react"
import { isVideo } from "../helpers/utils"
import Image from "./Image"
import VideoWithOverlay from "./VideoWithOverlay"

export interface Asset {
  mimeType: string
  url: string
  localFile: null | {
    publicURL: string
  }
}

const Asset = ({ asset }: { asset: Asset }) => {
  if (isVideo(asset?.mimeType)) {
    return asset?.url ? (
      <VideoWithOverlay videoUrl={asset?.url} isMuted={false} />
    ) : null
  } else if (asset?.localFile?.publicURL) {
    return <Image src={asset?.localFile?.publicURL} />
  }

  return null
}

export default Asset
