import React from "react"
import { Col, Row } from "reactstrap"
import ReactHtmlParser from "react-html-parser"
import Image from "../Image"
import { Link } from "gatsby"

const DownloadSection = ({ translatedSection, defaultSection }) => {
  const imageElement =
    defaultSection.downloadableElement.image.localFile.publicURL

  const linkData = translatedSection.downloadableElement.linkItem

  return (
    <Row className="DownloadSection">
      <Col md={12} lg={6} xl={6}>
        <h3 className="MediaItem__title">{translatedSection.title}</h3>
        <p className="MediaItem__description">
          {ReactHtmlParser(translatedSection.description.html)}
        </p>
      </Col>
      <Col md={12} lg={6} xl={6}>
        <div className="DownloadSection__element-container">
          <div className="DownloadSection__element" key={`header-element`}>
            <div
              className={`image-border--${translatedSection.downloadableElement.style}`}
            >
              <Image src={imageElement} />
            </div>
            <div className="DownloadSection__element__description">
              <p className="DownloadSection__element__description__title">
                {translatedSection.downloadableElement.title}
              </p>
              <Link to={linkData.link}>{linkData.text}</Link>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default DownloadSection
