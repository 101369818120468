import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { Media } from "reactstrap"
import { isVideo } from "../helpers/utils"
import { Asset } from "./Asset"
import CarouselIndicator from "./CarouselIndicator"
import Image from "./Image"
import VideoWithOverlay from "./VideoWithOverlay"

export enum MediaItemType {
  SingleAsset = "SingleAsset",
  Carrousel = "Carrousel",
}

type MediaItemProps = {
  type: MediaItemType
  carouselSlideTime: number | null
  assets: Asset[]
  left?: boolean
  right?: boolean
  title: string
  id?: string
  description?: string | JSX.Element | JSX.Element[]
  links: JSX.Element | JSX.Element[]
  marginBottom?: boolean
  center?: boolean
  noMargin?: boolean
  blueTitle?: boolean
  isMobile?: boolean
}

const MediaItem = ({
  type,
  assets,
  left,
  title,
  right,
  id,
  description,
  marginBottom,
  center,
  noMargin,
  blueTitle,
  carouselSlideTime = 5000,
  links,
  isMobile = false,
}: MediaItemProps) => {
  const [carouselIndex, setCarouselIndex] = useState(0)

  const incrementCarousel = () => {
    setCarouselIndex(currentIndex => {
      const newIndex = ++currentIndex

      if (newIndex >= assets.length) return 0

      return newIndex
    })
  }

  const className = classNames("MediaItem", {
    "MediaItem--margin-bottom": marginBottom,
  })

  const isCarousel = type === MediaItemType.Carrousel

  const renderContent = () => {
    let asset = null
    if (!assets || assets.length === 0) return null
    else if (isCarousel) {
      asset = assets[carouselIndex]
    } else {
      asset = assets[0]
    }

    if (isVideo(asset.mimeType)) {
      return <VideoWithOverlay isMuted={false} videoUrl={asset?.url} />
    }

    return <Media object tag={Image} src={asset?.localFile?.publicURL} />
  }

  const contentBody = (
    <div>
      <Media
        heading
        className={classNames("MediaItem__title", {
          "MediaItem__title--no-margin": noMargin,
          "MediaItem__title--blue": blueTitle,
        })}
      >
        {title}
      </Media>
      <div className="MediaItem__description">{description}</div>
      {isCarousel && (
        <CarouselIndicator
          currentIndex={carouselIndex}
          incrementIndex={incrementCarousel}
          length={assets.length}
          selectIndex={setCarouselIndex}
        />
      )}
      {links}
    </div>
  )

  useEffect(() => {
    const interval = setInterval(() => incrementCarousel(), carouselSlideTime)
    return () => clearInterval(interval)
  }, [])

  if (isMobile)
    return (
      <div>
        <Media
          heading
          className={classNames("MediaItem__title", {
            "MediaItem__title--no-margin": noMargin,
            "MediaItem__title--blue": blueTitle,
          })}
        >
          {title}
        </Media>
        <Media className="MediaItem__image">{renderContent()}</Media>
        {isCarousel && (
          <CarouselIndicator
            currentIndex={carouselIndex}
            incrementIndex={incrementCarousel}
            length={assets.length}
            selectIndex={setCarouselIndex}
            isMobile
          />
        )}
        <Media
          body
          className={`MediaItem__body ${
            center ? "d-flex align-items-center" : ""
          }`}
        >
          <div className="MediaItem__description">{description}</div>
        </Media>
        {links}
      </div>
    )
  if (left)
    return (
      <Media id={id} className={className}>
        <Media left className="MediaItem__image">
          {renderContent()}
        </Media>
        <Media
          body
          className={`MediaItem__body ${
            center ? "d-flex align-items-center" : ""
          }`}
        >
          {contentBody}
        </Media>
      </Media>
    )
  if (right) {
    return (
      <Media id={id} className={className}>
        <Media
          body
          className={`MediaItem__body ${
            center ? "d-flex align-items-center" : ""
          }`}
        >
          {contentBody}
        </Media>
        <Media right className="MediaItem__image">
          {renderContent()}
        </Media>
      </Media>
    )
  } else return null
}

export default MediaItem
