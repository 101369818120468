import React from "react"
import Image from "../Image"
import PersonCard from "../PersonCard"
import SectionHeader from "../SectionHeader"
import ReactHtmlParser from "react-html-parser"

const SpecialistSection = ({ defaultSection, translatedSection }) => {
  const specialists =
    translatedSection.graspTeamMemberGroups[0].graspTeamMembers.map(
      (member, memberIndex) => {
        const imageSrc =
          defaultSection.graspTeamMemberGroups[0].graspTeamMembers[memberIndex]
            .photo.localFile.publicURL

        return (
          <PersonCard
            img={<Image src={imageSrc} />}
            key={member.id}
            name={member.name}
            opinion={
              member.description
                ? ReactHtmlParser(member.description.html)
                : null
            }
            role={member.role}
          />
        )
      }
    )

  return (
    <div className="ForClinics__specialists">
      <SectionHeader
        title="Trusted by specialists"
        center
        description="THE GRASP"
      />
      <div className="d-flex">{specialists}</div>
    </div>
  )
}

export default SpecialistSection
