import React from "react"
import ReactHtmlParser from "react-html-parser"
import LinkItem from "../LinkItem"

const TextSection = ({ defaultSection, translatedSection }) => {
  const renderedText = translatedSection.description.html

  if (translatedSection.title === null) {
    return (
      <div className="ChronicDisease__description-text">
        {ReactHtmlParser(renderedText)}
        <LinkItem
          linkItemData={translatedSection.linkItem}
          defaultLinkItemData={defaultSection.linkItem}
        />
      </div>
    )
  } else {
    return (
      <div>
        <h2 className="ChronicDisease__description-text__title">
          {translatedSection.title}
        </h2>
        <div className="ChronicDisease__description-text">
          {ReactHtmlParser(renderedText)}
        </div>
      </div>
    )
  }
}

export default TextSection
