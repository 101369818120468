import React from "react"
import { Col, Row } from "reactstrap"
import Video from "./Video"

type VideContentProps = {
  title: string | JSX.Element
  description: string | JSX.Element
  content: string | JSX.Element
  videoURL: string
}

const VideContent = ({
  title,
  description,
  content,
  videoURL,
}: VideContentProps) => {
  return (
    <Row className="VideoSection__video-content">
      <Col md={12} lg={4}>
        {description && (
          <div className="VideoSection__video-content__description">
            {description}
          </div>
        )}

        <h3 className="VideoSection__video-content__header">{title}</h3>
        {content}
      </Col>
      <Col md={12} lg={8}>
        <Video
          className="VideoSection__video-content__video"
          videoSrcURL={videoURL}
          videoTitle="Grasp video"
        />
      </Col>
    </Row>
  )
}

export default VideContent
