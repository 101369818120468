import classNames from "classnames"
import React from "react"
import CarouselNext from "../icons/carousel_next.svg"

type CarouselIndicatorProps = {
  length: number
  currentIndex: number
  incrementIndex: () => void
  selectIndex: (val: number) => void
  isMobile?: boolean
}

const CarouselIndicator = ({
  length,
  currentIndex,
  incrementIndex,
  selectIndex,
  isMobile = false,
}: CarouselIndicatorProps) => {
  const indicators = [...Array(length)].map((_el, index) => {
    const isSelected = index === currentIndex

    const onClick = () => {
      selectIndex(index)
    }

    return (
      <div
        role="button"
        onClick={onClick}
        className={classNames("CarouselIndicator__indicator", {
          "CarouselIndicator__indicator--selected": isSelected,
        })}
      />
    )
  })

  if (isMobile)
    return (
      <div className="w-100 d-flex justify-content-center mt-2">
        <div className="CarouselIndicator">{indicators}</div>
      </div>
    )

  return (
    <div className="CarouselIndicator">
      {indicators}
      <span onClick={incrementIndex} role="button">
        <CarouselNext />
      </span>
    </div>
  )
}

export default CarouselIndicator
