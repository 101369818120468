import React, { useMemo } from "react"
import MediaItem, { MediaItemType } from "../MediaItem"
import ReactHtmlParser from "react-html-parser"
import LinkItem from "../LinkItem"
import classNames from "classnames"
import { LinkTypes } from "../../helpers/constants"
import { isMobileCheck, useWindowSize } from "../../helpers/utils"

const MediaItemSection = ({ defaultSection, translatedSection }) => {
  const windowSize = useWindowSize()
  const isMobile = isMobileCheck(windowSize)

  const renderLinks = () => {
    const links =
      translatedSection.linkItems?.map((linkData, index) => {
        const defaultLinkData = defaultSection.linkItems[index]

        const isButtonLink = defaultLinkData.type === LinkTypes.ButtonLink

        const isFullWidth = [LinkTypes.Link, LinkTypes.LinkWithArrow].includes(
          linkData.type
        )

        return (
          <span
            className={classNames({
              "ms-2": index !== 0 && !isFullWidth,
              "w-100": isFullWidth,
            })}
            style={
              isMobile && isButtonLink
                ? {
                    flexBasis: `calc(${
                      100 / translatedSection.linkItems.length
                    }% - 0.5rem)`,
                  }
                : undefined
            }
          >
            <LinkItem
              key={`${linkData.id}-${index}`}
              linkItemData={linkData}
              defaultLinkItemData={defaultLinkData}
              fullWidth={isMobile}
            />
            <br />
          </span>
        )
      }) ?? null

    return (
      <div className="w-100 d-flex flex-wrap align-items-center">{links}</div>
    )
  }

  const assetProps = useMemo(() => {
    if (
      defaultSection.carouselAssets &&
      defaultSection.carouselAssets.length > 0
    ) {
      return {
        assets: defaultSection.carouselAssets,
        type: MediaItemType.Carrousel,
        carouselSlideTime: defaultSection.carouselSlideTime,
      }
    }

    let assets = [defaultSection.asset]

    if (defaultSection.isExternalSource) {
      assets = [
        {
          mimeType: defaultSection.externalAssetMimeType,
          url: defaultSection.externalAssetUrl,
        },
      ]
    }

    return {
      assets: assets,
      type: MediaItemType.SingleAsset,
      carouselSlideTime: null,
    }
  }, [])

  return (
    <MediaItem
      isMobile={isMobile}
      center
      title={translatedSection.title}
      {...assetProps}
      left={translatedSection.imagePosition === "left"}
      right={translatedSection.imagePosition === "right"}
      description={ReactHtmlParser(translatedSection.description.html)}
      links={renderLinks()}
    />
  )
}

export default MediaItemSection
