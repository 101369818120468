import React from "react"
import { Media } from "reactstrap"
import Image from "../Image"
import ReactHtmlParser from "react-html-parser"

const ComingSoonSection = ({ translatedSection, defaultSection }) => {
  const imageUrl = defaultSection.asset.localFile.publicURL

  const contentBody = (
    <div className="text-center w-100">
      <div className="ComingSoonSection__header-image">
        <Image src={defaultSection.headerImage.localFile.publicURL} />
      </div>
      <Media heading className="ComingSoonSection__title">
        {translatedSection.title}
      </Media>
      <div>{ReactHtmlParser(translatedSection.description.html)}</div>
    </div>
  )

  return (
    <Media id={translatedSection.id} className="MediaItem">
      <Media left className="MediaItem__image">
        <Media object tag={Image} src={imageUrl} />
      </Media>
      <Media body className={`MediaItem__body d-flex align-items-center`}>
        {contentBody}
      </Media>
    </Media>
  )
}

export default ComingSoonSection
