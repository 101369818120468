import { Link } from "gatsby"
import React from "react"
import Image from "./Image"

import Arrow from "../icons/arrow_blue.svg"
import LinkItem from "./LinkItem"

type CardWithLinkProps = {
  image_url: string
  title: string
  image_alt?: string
  linkItem?: any
}

const CardWithLink = ({
  image_url,
  image_alt,
  title,
  linkItem,
}: CardWithLinkProps) => {
  return (
    <div className="VideoSection__card">
      <Image
        src={image_url}
        alt={image_alt}
        className="VideoSection__card__image"
      />
      <div className="VideoSection__card__header">{title}</div>
      {linkItem && <LinkItem linkItemData={linkItem} />}
    </div>
  )
}

export default CardWithLink
