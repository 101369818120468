import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import SectionHeader from "../SectionHeader"
import Image from "../Image"
import ReactHtmlParser from "react-html-parser"
import { Link } from "gatsby"
import Arrow from "../../icons/arrow_white_right.svg"

const OrderGraspSection = ({ defaultSection, translatedSection }) => {
  const images = defaultSection.orderImages.map(
    images => images.localFile.publicURL
  )

  const [activeImage, setActiveImage] = useState(0)

  const selectedImage = images[activeImage]

  return (
    <div className="OrderGraspSection">
      <SectionHeader
        center
        title={translatedSection.title}
        description={translatedSection.subTitle}
      />
      <Row className="OrderGraspSection__content">
        <Col md={5}>
          <div className="grasp-image-wrapper OrderGraspSection__content__image-wrapper d-flex flex-wrap">
            <Image src={selectedImage} style={{ width: "200px" }} />
            <div className="w-100 d-flex justify-content-center align-items-center">
              {images.map((el, index) => {
                let classNames =
                  "OrderGraspSection__content__image-wrapper__selector-element"
                if (activeImage === index)
                  classNames +=
                    " OrderGraspSection__content__image-wrapper__selector-element--active"

                return (
                  <div
                    key={`selector-element-${index}`}
                    onClick={() => setActiveImage(index)}
                    className={classNames}
                  />
                )
              })}
            </div>
          </div>
        </Col>
        <Col
          md={{
            offset: 1,
            size: 5,
          }}
        >
          <div className="OrderGraspSection__content__description">
            {ReactHtmlParser(translatedSection.description.html)}
          </div>
          <div className="OrderGraspSection__content__color-selector">
            <div className="w-100 d-flex">
              <div
                className="OrderGraspSection__content__color-selector__color-element OrderGraspSection__content__color-selector__color-element--blue"
                onClick={() => setActiveImage(0)}
              />
              <div
                className="OrderGraspSection__content__color-selector__color-element OrderGraspSection__content__color-selector__color-element--yellow"
                onClick={() => setActiveImage(1)}
              />
              <div
                className="OrderGraspSection__content__color-selector__color-element OrderGraspSection__content__color-selector__color-element--green"
                onClick={() => setActiveImage(2)}
              />
            </div>
          </div>
          <Link to={translatedSection.linkItem.link} className="CustomButton">
            {translatedSection.linkItem.text}
            <Arrow />
          </Link>
        </Col>
      </Row>
    </div>
  )
}

export default OrderGraspSection
