import React from "react"
import ReactHtmlParser from "react-html-parser"
import { ReactSVG } from "react-svg"
import { Row } from "reactstrap"
import Card from "../Card"

const CardSection = ({ defaultSection, translatedSection }) => {
  const cards = translatedSection.cardItems.map((card, cardIndex) => {
    const imageSrc =
      defaultSection.cardItems[cardIndex].asset.localFile.publicURL

    return (
      <Card
        key={`card--${cardIndex}`}
        title={card.title}
        description={ReactHtmlParser(card.description.html)}
        border
      >
        <ReactSVG src={imageSrc} />
      </Card>
    )
  })

  return <Row className="CardContainer">{cards}</Row>
}

export default CardSection
