import React, { useRef } from "react"
import CloseIcon from "../icons/close.svg"
import Image from "./Image"
import ReactHtmlParser from "react-html-parser"

type PersonCardModalProps = {
  img
  onClose: (ev) => void
  description: string
  title: string
  role: string
}

const PersonCardModal = ({
  description,
  img,
  onClose,
  role,
  title,
}: PersonCardModalProps) => {
  const divRef = useRef<null | HTMLDivElement>(null)

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        zIndex: 100,
      }}
      onClick={onClose}
    >
      <div className="PersonCardModal" ref={divRef}>
        <div className="PersonCardModal__image">
          <Image src={img} />
        </div>
        <div className="PersonCardModal__content">
          <div className="w-100 d-flex justify-content-end">
            <button className="PersonCardModal__close" onClick={onClose}>
              <CloseIcon />
            </button>
          </div>
          <div className="PersonCardModal__content__title">{title}</div>
          <div className="PersonCardModal__content__role">{role}</div>
          <div className="PersonCardModal__content__description">
            {ReactHtmlParser(description)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonCardModal
