import React from "react"
import DotsIcon from "../icons/dots.svg"

type PersonCardProps = {
  img: JSX.Element
  name: string
  role?: string
  email?: string
  description?: string
  opinion?: string
  small?: boolean
  onClick: () => void
}

const PersonCard = ({ img, name, role, small, onClick }: PersonCardProps) => {
  return (
    <div
      className={`PersonCard ${small ? "PersonCard--small" : ""}`}
      onClick={onClick}
    >
      <div>{img}</div>
      <div className="PersonCard__info">
        <div className="PersonCard__name">{name}</div>
        {role && <div className="PersonCard__role">{role}</div>}
        <span className="PersonCard__dots">
          <DotsIcon />
        </span>
      </div>
    </div>
  )
}

export default PersonCard
