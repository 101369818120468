import React from "react"
import CardWithLink from "../CardWithLink"
import SectionHeader from "../SectionHeader"

const ImageWithLinkSection = ({ translatedSection, defaultSection }) => {
  const imagesWithLink = translatedSection.imagesWithLink.map(
    (image, imageIndex) => {
      const imageSrc =
        defaultSection.imagesWithLink[imageIndex].asset.localFile.publicURL

      return (
        <CardWithLink
          key={`card-with-link--${imageIndex}`}
          title={image.title}
          linkItem={image.linkItem}
          image_url={imageSrc}
        />
      )
    }
  )

  return (
    <>
      <SectionHeader
        title={translatedSection.title}
        description={translatedSection.subTitle}
        center
      />
      <div className="VideoSection__card-wrapper w-100 d-flex justify-content-between">
        {imagesWithLink}
      </div>
    </>
  )
}

export default ImageWithLinkSection
