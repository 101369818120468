import { graphql, Link, useStaticQuery } from "gatsby"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap"
import { LanguageContext } from "../../context/LanguageContext"
import Control from "./components/Control"
import MenuToggler from "./components/MenuToggler"
import MobileMenu from "./components/MobileMenu"

const HEADER_COLLAPSE_POINT = 1440

const Header = () => {
  const headerQuery = useStaticQuery(graphql`
    query HeaderQuery {
      allGraphCmsNavigation(filter: { slug: { eq: "main" } }) {
        nodes {
          locale
          title
          id
          assets {
            id
            url
            localFile {
              id
              name
              publicURL
            }
          }
          navigationMenuElements {
            id
            locale
            title
            routeTarget
            sectionTarget
            slug
            isHidden
            asset {
              localFile {
                publicURL
              }
            }
            childNavigationMenuElements {
              title
              routeTarget
              id
              description
            }
          }
        }
      }
    }
  `)

  const { language } = useContext(LanguageContext)

  const defaultObject = headerQuery.allGraphCmsNavigation.nodes.find(
    translationObject => {
      return translationObject.locale === "en"
    }
  )

  const logoData = defaultObject.assets[0]

  const translationObject = useMemo(
    () =>
      headerQuery.allGraphCmsNavigation.nodes.find(translationObject => {
        return translationObject.locale === language
      }),
    [language]
  )

  const [collapsed, setCollapsed] = useState(() => {
    if (typeof window !== "undefined") {
      if (window.innerWidth >= HEADER_COLLAPSE_POINT) return false
      else return true
    } else return false
  })
  const toggleNavbar = () => setCollapsed(!collapsed)

  const renderHeader = useCallback(() => {
    return translationObject.navigationMenuElements.map((element, index) => {
      if (["login", "order"].includes(element.slug)) {
        const defElement = defaultObject.navigationMenuElements[index]

        return (
          <NavItem onClick={toggleNavbar} key={element.id} id={element.slug}>
            <a href={element.routeTarget}>
              <div className={`btn Header__navbar__${element.slug}`}>
                {element.title}
                {defElement && defElement.asset && (
                  <img
                    src={defElement.asset?.localFile?.publicURL}
                    alt={defElement.asset.localFile.publicURL}
                  />
                )}
              </div>
            </a>
          </NavItem>
        )
      } else if (element.childNavigationMenuElements.length === 0) {
        let navLink = (
          <NavLink tag={Link} to={element.routeTarget}>
            {element.title}
          </NavLink>
        )

        if (element.sectionTarget) {
          const onClick = () => {
            const elementFound = document.getElementById(element.sectionTarget)
            if (elementFound) elementFound.scrollIntoView()
          }

          navLink = (
            <NavLink className="cursor-pointer" onClick={onClick}>
              {element.title}
            </NavLink>
          )
        }

        return <NavItem key={element.id}>{navLink}</NavItem>
      } else {
        const childTranslationObjects = element.childNavigationMenuElements.map(
          childTranslationObject => {
            return (
              <DropdownItem
                className="menu__item"
                key={`dropdown-${childTranslationObject.id}`}
              >
                <Link
                  key={childTranslationObject.id}
                  to={childTranslationObject.routeTarget}
                >
                  <div className="menu__item__title">
                    {childTranslationObject.title}
                  </div>
                  <div className="menu__item__description">
                    {childTranslationObject.description}
                  </div>
                </Link>
              </DropdownItem>
            )
          }
        )

        return (
          <UncontrolledDropdown key={element.id} nav>
            <DropdownToggle nav caret>
              {element.title}
            </DropdownToggle>
            <DropdownMenu right className="menu">
              {childTranslationObjects}
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      }
    })
  }, [translationObject])

  const onResize = e => {
    if (e.target.outerWidth < HEADER_COLLAPSE_POINT) {
      setCollapsed(true)
    } else {
      setCollapsed(false)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", onResize)
      window.addEventListener("fullscreenchange", onResize)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", onResize)
        window.removeEventListener("fullscreenchange", onResize)
      }
    }
  }, [])

  const [isMobileOpen, setMobileOpen] = useState(false)
  const toggleMobileMenu = () => setMobileOpen(val => !val)

  return (
    <>
      <header className="Header">
        <Navbar color="faded" light className="Header__navbar">
          <NavbarBrand className="Header__navbar__logo" href="/">
            {logoData && (
                <img
                    key={logoData.id}
                    src={logoData.url}
                    alt="logo-image"
                />
            )}
          </NavbarBrand>
          <Nav navbar></Nav>
        </Navbar>
      </header>
    </>
  )
}

export default Header
