import React from "react"
import Image from "./Image"

type ImageWithDescriptionProps = {
  imageSrc: string
  title: string
  description: string
}

const ImageWithBottomDescription = ({
  imageSrc,
  title,
  description,
}: ImageWithDescriptionProps) => {
  return (
    <div className="ImageWithBottomDescription">
      <div className="ImageWithBottomDescription__image">
        <Image src={imageSrc} />
      </div>

      <div className="ImageWithBottomDescription__title">{title}</div>
      <div className="ImageWithBottomDescription__description">
        {description}
      </div>
    </div>
  )
}

export default ImageWithBottomDescription
