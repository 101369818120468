import React, {useEffect, useRef, useState} from "react"
import PlayIcon from "../icons/play.svg"
import Hls from 'hls.js'

type VideoWithOverlayProps = {
  videoUrl: string
  isMuted: boolean
}

const VideoWithOverlay = ({ videoUrl }: VideoWithOverlayProps) => {
  const videoRef = useRef<null | HTMLVideoElement>(null)
  const [isStarted, setStarted] = useState(false)

  const onClickVideo = () => {
    if (videoRef && videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setStarted(true);
      } else {
        videoRef.current.pause();
        setStarted(false);
      }
    }
  }

  useEffect(() => {
    if(Hls.isSupported() && videoRef && videoRef.current) {
      const hls = new Hls();
      hls.loadSource(videoUrl);
      hls.attachMedia(videoRef.current);
    }
  }, [videoRef]);

  return (
    <div className="VideoWithOverlay" onClick={onClickVideo}>
      {!isStarted && (
        <div className="VideoWithOverlay__play">
          <PlayIcon />
        </div>
      )}
      <video
          title={videoUrl}
          className="VideoWithOverlay__video"
          controls={isStarted}
          ref={videoRef}
      >
        <source src={videoUrl} />
      </video>
    </div>
  )
}

export default VideoWithOverlay
