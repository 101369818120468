import { useLayoutEffect, useState } from "react"
import { ArticleItemProps } from "../components/ArticleItem"
import { ArticleTypes } from "./constants"

export function returnTheme() {
  return process.env.GATSBY_INITIAL_THEME || "gatsbyChange"
}

export const convertArticleTypeToRoute = articleType => {
  const blogTypeMapper = {
    [ArticleTypes.blogEntry]: "blog",
    [ArticleTypes.successStory]: "success_story",
    [ArticleTypes.video]: "video",
  }

  return blogTypeMapper[articleType]
}

type WidthDataProps = Pick<
  ArticleItemProps,
  "desktopItemWidth" | "tabletItemWidth" | "mobileItemWidth"
>

export const formatArticlesToArticleItemProps = (
  groupData,
  languageSelected,
  articleType: ArticleTypes,
  widthData: WidthDataProps
) => {
  const sortedGroupData = sortArticleGroup(groupData)

  return sortedGroupData.map(({ nodes }) => {
    const [defaultNode] = nodes

    const translationNode = nodes.find(el => el.locale === languageSelected)

    const redirectLink = `/${convertArticleTypeToRoute(articleType)}/${
      defaultNode.slug
    }`

    const isVideo = defaultNode.asset.mimeType.includes("video")

    const props: ArticleItemProps = {
      description: translationNode.shortDescription,
      title: translationNode.title,
      redirectLink,
      imgSrc: !isVideo
        ? defaultNode.asset.localFile.publicURL
        : defaultNode.videoThumbnail.localFile.publicURL,
      isVideo,
      isHighlighted: false,
      ...widthData,
    }

    return props
  })
}

export const sortArticleGroup = articleGroup => {
  return articleGroup.sort((groupA, groupB) => {
    const [engNodeA] = groupA.nodes
    const [engNodeB] = groupB.nodes

    const dateA = new Date(engNodeA.createdAt)
    const dateB = new Date(engNodeB.createdAt)

    if (dateA > dateB) return "-1"
    if (dateB > dateA) return "1"
    else return "0"
  })
}

export const returnWidthInfo = defaultTranslation => {
  return {
    desktopItemWidth: defaultTranslation.desktopWidth,
    tabletItemWidth: defaultTranslation.tabletWidth,
    mobileItemWidth: defaultTranslation.mobileWidth,
  }
}

export const formatDate = (dateString: string) => {
  const dateObj = new Date(dateString)

  const month = dateObj.toLocaleString("default", { month: "long" })

  return `${dateObj
    .getDate()
    .toString()
    .padStart(2, "0")} ${month} ${dateObj.getFullYear()}`
}

export const isVideo = (mimeType: string) => {
  if (!mimeType) return false

  return mimeType.includes("video")
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useLayoutEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}

export function isMobileCheck(windowSize: { width?: number; height?: number }) {
  if (!windowSize.width) return false

  const tabletBreakpoint = 992

  return windowSize.width < tabletBreakpoint
}
