import React from "react"
import CountUp from "react-countup"

function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}

const NumberSection = ({ translatedSection }) => {
  const items = translatedSection.numbersWithDescription

  const renderedNumberItems = items?.map((el, index) => (
    <div className="NumberSection__item" key={`${el.name}=${index}`}>
      <div className="NumberSection__item__value">
        <CountUp
          end={el.value}
          duration={el.value / 5000}
          formattingFn={numberWithSpaces}
        />
      </div>
      <div className="NumberSection__item__title">{el.name}</div>
    </div>
  ))

  return (
    <div className="NumberSection">
      <h3 className="NumberSection__title text-center w-100">
        {translatedSection.title}
      </h3>
      <p className="NumberSection__sub-title text-center w-100">
        {translatedSection.subTitle}
      </p>
      {renderedNumberItems}
    </div>
  )
}

export default NumberSection
