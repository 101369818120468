import React from "react"
import ReactHtmlParser from "react-html-parser"

import VideoContent from "../VideoContent"

const VideoSection = ({ defaultSection, translatedSection }) => {
  const videoURL = defaultSection.asset.url

  return (
    <div className="ForInvestors__interested-section">
      <VideoContent
        videoURL={videoURL}
        title={translatedSection.title}
        description={translatedSection.subTitle}
        content={ReactHtmlParser(translatedSection.description.html)}
      />
    </div>
  )
}

export default VideoSection
