import React from "react"
import Image from "../Image"
import SectionHeader from "../SectionHeader"

const PartnersSection = ({ defaultSection, translatedSection }) => {
  const formattedPartners = defaultSection.logos.map(element => {
    const imageSrc = element.localFile.publicURL

    return (
      <Image
        src={imageSrc}
        style={{ width: element.width, height: element.height }}
      />
    )
  })

  return (
    <div className="PartnersSection">
      <SectionHeader title={translatedSection.title} center />
      <div className="d-flex flex-wrap justify-content-center PartnersSection__container">
        {formattedPartners}
      </div>
    </div>
  )
}

export default PartnersSection
