import React from "react"
import { Col, Row } from "reactstrap"
import Image from "../Image"
import SectionHeader from "../SectionHeader"
import ReactHtmlParser from "react-html-parser"
import LinkItem from "../LinkItem"

const ImageWithTwoDescriptions = ({ defaultSection, translatedSection }) => {
  const imageURL = defaultSection.asset.localFile.publicURL
  const haveBackground = defaultSection.imageHaveBackground || false

  let imageLinks: JSX.Element[] | null = null
  if (translatedSection.imageWithLinks) {
    imageLinks = defaultSection.imageWithLinks.map(imageWithLink => {
      return (
        <a key={imageWithLink.link} href={imageWithLink.link}>
          <img src={imageWithLink.imageFile.localFile.publicURL} />
        </a>
      )
    })
  }

  const image = haveBackground ? (
    <Col
      md={{
        size: 12,
        order: 3,
      }}
      lg={{
        size: 6,
        order: 2,
      }}
      sm={{
        size: 12,
        order: 3,
      }}
      className="text-center d-flex justify-content-center"
    >
      <div className="grasp-image-wrapper w-100">
        <Image
          src={imageURL}
          imgStyle={{
            objectFit: "none",
          }}
        />
      </div>
    </Col>
  ) : (
    <Col
      md={{
        size: 12,
        order: 3,
      }}
      lg={{
        size: 6,
        order: 2,
      }}
      className="text-center "
    >
      <Image
        src={imageURL}
        style={{
          width: "90%",
        }}
      />
    </Col>
  )

  let linkItem =
    translatedSection?.linkItem?.isHidden != true ? (
      <LinkItem
        linkItemData={translatedSection.linkItem}
        defaultLinkItemData={defaultSection.linkItem}
      />
    ) : null

  return (
    <div className="InsightSection Main__image-description">
      <SectionHeader
        title={translatedSection.title}
        description={translatedSection.subTitle}
        center
      />
      <Row className="Main__image-description__content mb-4">
        <Col
          md={{
            size: 12,
            order: 1,
          }}
          sm={{
            size: 12,
            order: 1,
          }}
          lg={{
            size: 3,
            order: 1,
          }}
        >
          {ReactHtmlParser(translatedSection.leftDescription.html)}
        </Col>
        {image}
        <Col
          sm={{
            size: 12,
            order: 2,
          }}
          md={{
            size: 12,
            order: 2,
          }}
          lg={{
            size: 3,
            order: 3,
          }}
          className="d-flex align-items-end"
        >
          <div>{ReactHtmlParser(translatedSection.rightDescription.html)}</div>
        </Col>
      </Row>
      <div className="d-flex align-items-center justify-content-center image-description__download">
        {imageLinks}
      </div>
      {linkItem}
    </div>
  )
}

export default ImageWithTwoDescriptions
