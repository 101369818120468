import React from "react"

type VideoProps = {
  videoSrcURL: string
  videoTitle: string
  className?: string
}

const Video = ({ videoSrcURL, videoTitle, className }: VideoProps) => {
  return (
    <div className={`video ${className ? className : ""}`}>
      <video title={videoTitle} controls autoPlay loop muted>
        <source src={videoSrcURL} type="video/mp4" />
      </video>
    </div>
  )
}

export default Video
